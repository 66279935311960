import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import queryString from 'query-string';
import useBudget from 'Portal/Views/Budget/useBudget'
import BudgetCode from 'Portal/Views/Budget/BudgetCode'
import BudgetCustomerFormPymePack from 'Portal/Views/Budget/BudgetCustomerFormPymePack'
import Budget from 'Portal/Views/Budget/Budget'
import BudgetInsuredSummary from 'Portal/Views/Budget/BudgetPersons/BudgetInsuredSummary'
import BudgetRequirements from 'Portal/Views/Budget/BudgetRequirements'
import BudgetPay from 'Portal/Views/Budget/BudgetPay'
import BudgetResult from 'Portal/Views/Budget/BudgetResult'
import RiskData from 'Portal/Views/Budget/BudgetHome/RiskData'
import BudgetSMES from 'Portal/Views/Budget/BudgetSMES/BudgetSMES'
import BudgetLocations from 'Portal/Views/Budget/BudgetPatrimonial/BudgetLocations'
import BudgetSMESSummary from 'Portal/Views/Budget/BudgetSMES/BudgetSMESSummary'
import BudgetPatrimonialPaqPortal from 'Portal/Views/Budget/BudgetPatrimonial/BudgetPatrimonialPaqPortal'
import BudgetPatrimonialDet from 'Portal/Views/Budget/BudgetPatrimonial/BudgetPatrimonialDet'
import TemplateBlank from 'LandingPageMaterial/Layout/TemplateBlank'

export default function CotizarPack(props) {
    let params_url = queryString.parse(props.location.search)
    const { id, indpymepackage, workflowId, location } = props
    const { getBudgetbyHash, getBudgetbyId, showBudget, setShowBudget, getBudgetAreaName, getSMESBudgetbyId, ...objBudget } = useBudget()
    const [budgetAreaName, setBudgetAreaName] = useState()
    const [tabSelectedBudgetSMES, setTabSelectedBudgetSMES] = useState()
    const [showBackButtonOnBudgetSMES, setShowBackButtonOnBudgetSMES] = useState(false)
    const [thirdAccountDataForm, setThirdAccountDataForm] = useState(null)
    const [selectedPlanCtlr, setSelectedPlanCtlr] = useState(null)
    const [riskNatureDescription, setRiskNatureDescription] = useState()
    const [specificRiskNatureDescription, setSpecificRiskNatureDescription] = useState()
    const [riskNatureCode, setRiskNatureCode] = useState()
   
    async function searchBudgetPublic() {
        await getBudgetbyHash(params_url.id)
    }
    async function searchBudget(planId) {
        let budgetId
        if (!id && workflowId) {
            const params = { p_workflow_id: workflowId }
            const response = await Axios.post('/dbo/budgets/get_budget_id_from_workflow_id', params)
            budgetId = response.data.result
            setShowBackButtonOnBudgetSMES(true)
        } else {
            budgetId = id
        }

        const localBudgetAreaName = await getBudgetAreaName(objBudget.info[0].BUDGET_ID)
        setBudgetAreaName(localBudgetAreaName)
        
        await getBudgetbyId(objBudget.info[0].BUDGET_ID)                            
        
    }


    async function searchBudgetDet(planId) {
        let budgetId
        if (!id && workflowId) {
            const params = { p_workflow_id: workflowId }
            const response = await Axios.post('/dbo/budgets/get_budget_id_from_workflow_id', params)
            budgetId = response.data.result
            setShowBackButtonOnBudgetSMES(true)
        } else {
            budgetId = id
        }
        const localBudgetAreaName = await getBudgetAreaName(objBudget.info[0].BUDGET_ID)
        setBudgetAreaName(localBudgetAreaName)
        
        await getSMESBudgetbyId(objBudget.info[0].BUDGET_ID, planId)                            
        
    }


    async function setBasicInfo(){
        const params_groups = { p_is_pyme_pack: 'S'}
        async function getCoveragesGroups() {
            const result = await Axios.post('/dbo/budgets/get_coverages_groups', params_groups)
            return [...result.data.result]
        }
        const assetRiskGroups = { assets_risk_groups: [{ asset_class: "VI", asset_description: "VALORES PARA INCENDIO", is_parent: "Y" }] }
        const coveragesGroupArray = await getCoveragesGroups()
        const coveragesGroups = { coverages_groups: coveragesGroupArray }
        const params = {p_partner_code: objBudget.budgetInfo.partner_code, 
                        p_budget_id:objBudget.info[0].BUDGET_ID, 
                        p_json_info: JSON.stringify({ country_code_in_direction: "001",
                        risk_nature_code: riskNatureCode, 
                        risk_nature_description: riskNatureDescription,
                        specific_risk_nature_description: specificRiskNatureDescription,
                       ...assetRiskGroups, ...coveragesGroups }) }
        
        const response = await Axios.post('/dbo/budgets/set_budget_basic_info', params)               
    }

    async function handleBack() {
        setShowBudget(false)
        const apiServicePath = '/dbo/budgets/set_prev_status_budget_v2' 
        const params = { p_budget_id: objBudget.info[0].BUDGET_ID }
        await Axios.post(apiServicePath, params)
        await searchBudget()
    }

    async function handleFinish() {
        setShowBudget(false)
        searchBudgetPublic()
        searchBudget()
    }

    async function handleFinishBudgetSMES(planId, tabSelected) {
        setTabSelectedBudgetSMES(tabSelected)
        setShowBudget(true)
        await searchBudgetDet(planId)
    }

    async function handleFinishLocation(planId) {
        setShowBudget(false)
        await setBasicInfo()
        await searchBudgetDet(planId)
    }


    useEffect(() => {
        searchBudgetPublic()
    }, [])
    function budgetController() {
        const { plans, setPlans, info, budgetInfo } = objBudget        
        const status = objBudget.info[0].STATUS
        switch (status) {
            case 'CREATED':
                return <BudgetCode objBudget={objBudget} onFinish={handleFinish} />
            case 'VALID_PACKAGE':
                return <TemplateBlank><Budget objBudget={objBudget} getBudgetAreaName={getBudgetAreaName} setBudgetAreaName={setBudgetAreaName} setSelectedPlanCtlr={setSelectedPlanCtlr} getBudgetbyId={getBudgetbyId}  getSMESBudgetbyId={getSMESBudgetbyId} onFinish={handleFinish} title={objBudget.info[0].BUDGET_DESCRIPTION} /></TemplateBlank> 
            case 'APPROVAL_REQUESTED':
                return <BudgetSMES objBudget={objBudget} onFinish={handleFinishBudgetSMES} tabSelected={tabSelectedBudgetSMES} showBackButton={showBackButtonOnBudgetSMES}/>
            case 'APPROVED_PACKAGE':
                return <TemplateBlank><BudgetPatrimonialDet objBudget={objBudget} onFinish={handleFinishBudgetSMES} handleBack={handleBack} tabSelected={tabSelectedBudgetSMES} showBackButton={showBackButtonOnBudgetSMES} getBudgetbyId={getBudgetbyId}/></TemplateBlank>
            case 'LOCATIONS_PACKAGE':
                return <TemplateBlank><BudgetLocations objBudget={objBudget} 
                                        onFinish={handleFinishLocation} 
                                        onBack={handleBack}
                                        setRiskNatureDescription={setRiskNatureDescription}
                                        setSpecificRiskNatureDescription={setSpecificRiskNatureDescription}  
                                        setRiskNatureCode={setRiskNatureCode}/></TemplateBlank>
            case 'HOLDER_PACKAGE':
                return <TemplateBlank><BudgetPatrimonialPaqPortal planBuy={selectedPlanCtlr}  
                insuranceBrokerCode={budgetInfo.partner_code} 
                budgetInfo={budgetInfo} 
                info={info}
                plan={selectedPlanCtlr}
                objBudget={objBudget}
                handleFinish={handleFinish}
                onBack={handleBack}
                getBudgetbyId={getBudgetbyId}
                /></TemplateBlank>
            case 'INVOICEER_PACKAGE':
                return <TemplateBlank><BudgetCustomerFormPymePack 
                    objBudget={objBudget} 
                    index="INVOICEER" 
                    onFinish={handleFinish} 
                    title="Datos de la persona que pagará la póliza" 
                    onBack={handleBack}
                    thirdAccountDataForm={thirdAccountDataForm}
                    setThirdAccountDataForm={setThirdAccountDataForm}
                    location={location}
                    indpymepackage={indpymepackage}
                /></TemplateBlank>
            
            case 'RISKDATA':
                return <TemplateBlank><RiskData objBudget={objBudget} onFinish={handleFinish} onBack={handleBack} /></TemplateBlank>
            case 'SUMMARY':
                return <TemplateBlank><BudgetInsuredSummary objBudget={objBudget} onFinish={handleFinish} /></TemplateBlank>
            case 'SME_SUMMARY':
                return <TemplateBlank><BudgetSMESSummary indpymepackage={indpymepackage} objBudget={objBudget} onFinish={handleFinishBudgetSMES} onBack={handleBack} /></TemplateBlank>
            case 'INCLUDED_PACKAGE':                
                    return <TemplateBlank><BudgetRequirements 
                        objBudget={objBudget} 
                        onFinish={handleFinish} 
                        isPortal={true}     
                        thirdAccountDataForm={thirdAccountDataForm}
                    /></TemplateBlank>
                
            case 'ACTIVATED':
                return <TemplateBlank><BudgetPay objBudget={objBudget} onFinish={handleFinish} /></TemplateBlank>
            case 'PAYED':
                return <TemplateBlank><BudgetResult objBudget={objBudget} onFinish={handleFinish} /></TemplateBlank>
            default:
                return null;
        }
    }
    return (
        showBudget && budgetController()
    )
}
